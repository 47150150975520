import { Theme } from "@mui/system/createTheme";
import FaceTecSDK, { FaceTecCustomization } from "facetec-sdk";

const customizationFromTheme = (theme: Theme): FaceTecCustomization => {
  const customization = new FaceTecSDK.FaceTecCustomization();

  customization.overlayCustomization.backgroundColor =
    theme.palette.background.default;
  customization.overlayCustomization.showBrandingImage = true;

  customization.ovalCustomization.strokeColor =
    theme.palette.background.default;
  customization.ovalCustomization.strokeWidth = 1;

  customization.frameCustomization.backgroundColor =
    theme.palette.background.default;
  customization.frameCustomization.borderColor =
    theme.palette.background.default;

  customization.guidanceCustomization.foregroundColor =
    theme.palette.text.primary;
  customization.guidanceCustomization.backgroundColors =
    theme.palette.background.default;

  customization.guidanceCustomization.buttonTextNormalColor =
    theme.palette.text.primary;
  customization.guidanceCustomization.buttonTextHighlightColor =
    theme.palette.text.primary;
  customization.guidanceCustomization.buttonTextDisabledColor =
    theme.palette.text.primary;

  customization.guidanceCustomization.buttonBackgroundNormalColor =
    theme.palette.primary.dark;
  customization.guidanceCustomization.buttonBackgroundHighlightColor =
    theme.palette.primary.main;
  customization.guidanceCustomization.buttonBackgroundDisabledColor =
    theme.palette.action.disabledBackground;
  customization.guidanceCustomization.buttonTextDisabledColor =
    theme.palette.text.disabled;

  customization.initialLoadingAnimationCustomization.foregroundColor =
    theme.palette.primary.main;
  customization.initialLoadingAnimationCustomization.messageTextColor =
    theme.palette.text.primary;

  customization.ovalCustomization.progressColor1 = "#C373DD";
  customization.ovalCustomization.progressColor2 = "#F4B98D";

  customization.feedbackCustomization.backgroundColor =
    theme.palette.background.paper;
  customization.feedbackCustomization.textColor = theme.palette.text.primary;

  customization.resultScreenCustomization.backgroundColors =
    theme.palette.background.default;
  customization.resultScreenCustomization.foregroundColor =
    theme.palette.text.primary;

  customization.resultScreenCustomization.activityIndicatorColor =
    theme.palette.primary.light;
  customization.resultScreenCustomization.uploadProgressFillColor =
    theme.palette.primary.light;
  customization.resultScreenCustomization.resultAnimationForegroundColor =
    theme.palette.primary.light;

  customization.enableCameraPermissionsHelpScreen = true;
  customization.ovalCustomization.strokeWidth = 10;

  return customization;
};

export default customizationFromTheme;
