import { ApiRx } from "@polkadot/api";
import { StateVariant } from "@tagged-state/core";
import { firstValueFrom } from "rxjs";

interface SyncingData {
  startingBlock: number;
  currentBlock: number;
  highestBlock: number;
}

export type SyncState =
  | StateVariant<"uninit">
  | StateVariant<"syncing", SyncingData>
  | StateVariant<"ready">
  | StateVariant<"error", { error: Error }>;

export interface SyncStateJSON {
  startingBlock: number;
  currentBlock: number;
  highestBlock: number;
}

export interface HealthJSON {
  isSyncing: {
    isTrue: boolean;
  };
}

export const systemSyncState = (api: ApiRx): Promise<SyncStateJSON> =>
  firstValueFrom(api.rpc.system.syncState()).then((syncState) => {
    const currentBlock = syncState.currentBlock.toNumber();
    const startingBlock = syncState.startingBlock.toNumber();
    const highestBlock = Number(syncState.highestBlock.unwrap().toNumber());

    return {
      currentBlock,
      startingBlock,
      highestBlock,
    };
  });

export const systemHealth = (api: ApiRx): Promise<HealthJSON> =>
  firstValueFrom(api.rpc.system.health()).then((health) => ({
    isSyncing: { isTrue: health.isSyncing.isTrue },
  }));

export default async function syncState(api: ApiRx): Promise<SyncState> {
  const health = await systemHealth(api);
  const isSyncing = health.isSyncing.isTrue;

  if (isSyncing) {
    const state = await systemSyncState(api);
    return {
      tag: "syncing",
      data: state,
    };
  }

  return {
    tag: "ready",
    data: {},
  };
}
