import { ApiRx } from "@polkadot/api";

import { authenticateV2 as wrappedAuthenticateV2 } from "../wrappers";
import { RetryOrBlobSchema } from "./errorData";
import processRpcCall, { specialErrorPagesMapFn } from "./processRpcCall";

export type AuthenticateV2Response = Awaited<ReturnType<typeof authenticateV2>>;

const errorMessageToSpecialErrorPage = {
  "server error: person not found": "personNotFound",
  "server error: signature invalid": "signatureInvalid",
  "validator key not available": "validatorKeyNotAvailable",
} as const;

const authenticateV2 = async (
  api: ApiRx,
  livenessData: {
    faceScan: string;
    auditTrailImage: string;
    lowQualityAuditTrailImage: string;
  },
) =>
  processRpcCall(
    () => wrappedAuthenticateV2(api, livenessData),
    RetryOrBlobSchema,
    specialErrorPagesMapFn(errorMessageToSpecialErrorPage),
  );

export default authenticateV2;
