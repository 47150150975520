import { u128 } from "@polkadot/types-codec";
import { Balance } from "@polkadot/types/interfaces";
import { formatBalance } from "@polkadot/util";

export const BNtoUnit = (
  value: u128 | Balance,
  decimals: number,
  withUnit: string,
) =>
  formatBalance(value, {
    decimals,
    withUnit,
    withSi: true,
    forceUnit: "Unit",
  });
