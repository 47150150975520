import React, { useCallback, useState } from "react";
import Slide, { SlideProps } from "@mui/material/Slide";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import AppUpdateContext from "./AppUpdateContext";

const updateAndReload = (registration: ServiceWorkerRegistration) => {
  registration?.waiting?.postMessage({ type: "SKIP_WAITING" });
  window.location.reload();
};

const SlideTransition: React.FC<SlideProps> = (props) => {
  return <Slide {...props} direction="up" />;
};

const AppUpdateSnackbar: React.FC = () => {
  const [registration, setRegistration] =
    useState<ServiceWorkerRegistration | null>(null);

  const handleClose = useCallback(
    (_event: unknown, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }

      setRegistration(null);
    },
    [setRegistration],
  );

  const handleUpdate = useCallback(() => {
    if (registration) {
      updateAndReload(registration);
    }
    setRegistration(null);
  }, [registration]);

  return (
    <>
      <AppUpdateContext onUpdate={setRegistration} />
      <Snackbar
        style={{
          // Make sure this appears atop of FaceTec overlay.
          zIndex: 16777201,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={!!registration}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        message="App update available!"
        action={
          <React.Fragment>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={handleUpdate}
            >
              Update and reload
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
};

export default AppUpdateSnackbar;
