import { ApiRx } from "@polkadot/api";
import { firstValueFrom } from "rxjs";
import { StateVariant } from "@tagged-state/core";

export type BioauthStatusState =
  | StateVariant<"uninit">
  | StateVariant<"active", { expiresAt: number }>
  | StateVariant<"inactive">
  | StateVariant<"unknown">
  | StateVariant<"error", { error: Error }>;

export default async function bioauthStatus(api: ApiRx) {
  return firstValueFrom(api.rpc.bioauth.status());
}
