import type { Observable } from "rxjs";
import {
  EpochProgress,
  EpochProgressState,
} from "../../lib/dashboard/epochProgress";
import createTaggedStateObservable from "../../lib/observable-tagged-state/createTaggedStateObservable";

const { Guard: EpochProgressGuard, useDriveTaggedStateFromObservable } =
  createTaggedStateObservable<EpochProgress, EpochProgressState>();

const useEpochProgressInit = (observable$: Observable<EpochProgress>) =>
  useDriveTaggedStateFromObservable(
    observable$,
    async (value) => {
      return value.total
        ? {
            tag: "progress",
            data: value,
          }
        : {
            tag: "uninit",
            data: {},
          };
    },
    async (error) => ({ tag: "error", data: { error } }),
  );

export { EpochProgressGuard, useEpochProgressInit };
