import { stringify } from "@polkadot/util";
import RpcError from "@polkadot/rpc-provider/coder/error";

/// This code is copied from polkadot-js
/// https://github.com/polkadot-js/api/blob/86398e21f8d60c62c201b85bf8e5a61cdbe929d1/packages/rpc-provider/src/coder/index.ts#L10
function formatErrorData(data: unknown) {
  if (data === undefined) {
    return "";
  }
  const formatted = `: ${
    typeof data === "string"
      ? data
          .replace(/Error\("/g, "")
          .replace(/\("/g, "(")
          .replace(/"\)/g, ")")
          .replace(/\(/g, ", ")
          .replace(/\)/g, "")
      : stringify(data)
  }`;

  return formatted.length <= 256
    ? formatted
    : `${formatted.substring(0, 255)}…`;
}

export const extractMessage = <T>(error: RpcError<T>) => {
  const { message, code, data } = error;

  const beforeRawMessage = `${code}: `;
  const afterRawMessage = formatErrorData(data);

  const beforeRawMessageIndex = message.indexOf(beforeRawMessage);
  const afterRawMessageIndex = message.lastIndexOf(afterRawMessage);

  if (
    beforeRawMessageIndex === 0 &&
    afterRawMessageIndex === message.length - afterRawMessage.length
  ) {
    return message.substring(beforeRawMessage.length, afterRawMessageIndex);
  }

  return message;
};
