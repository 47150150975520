import { GAP, GenericErrorShape } from "../GenericErrorPage";

const params = {
  lookupMap: {
    nonceAlreadyUsed: {
      title: "Auth Ticket Already Used",
      hint: [
        "The auth ticket you provided has already been used.",
        GAP,
        "This should not happen normally, so this means you've encountered some bug.",
        "Please report this occurrence.",
      ],
    } as GenericErrorShape,
    alreadyAuthenticated: {
      title: "Already Authenticated",
      hint: [
        "You are already authenticated and trying to authenticate again too soon.",
        GAP,
        "Wait for your current authentication to expire before",
        "attempting to authenticate again.",
      ],
    } as GenericErrorShape,
    authTicketSignatureInvalid: {
      title: "Invalid Biometrics Server Signature",
      hint: [
        "The auth ticket signature is incorrect or by an irrelevant (i.e. outdated/replaced) biometrics server.",
        GAP,
        "It might be that the biometrics server rotated while you were sending the auth ticket,",
        "if this is the case - get an auth ticket from the new biometric server and try again.",
      ],
    } as GenericErrorShape,
  },
  defaultLookup: {
    title: "Authentication Failed",
    hint: ["Something went wrong."],
  } as GenericErrorShape,
} as const;

export default params;
