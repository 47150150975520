import { ApiRx } from "@polkadot/api";
import { StateVariant } from "@tagged-state/core";
import {
  createContext,
  createExpectContextState,
  createTaggedStateHook,
} from "@tagged-state/react";

export type State =
  | StateVariant<"uninit">
  | StateVariant<"pending">
  | StateVariant<"ready", { api: ApiRx }>
  | StateVariant<"error", { error: Error }>;

const { Context, ComponentGuard } = createContext<State>({
  tag: "uninit",
  data: {},
});

export const ApiContext = Context;
export const ApiGuard = ComponentGuard;

export const RequireApi = createExpectContextState(Context, "ready" as const);
export const useApi = createTaggedStateHook(Context, "ready" as const);
