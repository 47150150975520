import RpcError from "@polkadot/rpc-provider/coder/error";
import { z } from "zod";
import { catchError, OperatorFunction, of, map, pipe } from "rxjs";
import type { ExtrinsicStatus } from "@polkadot/types/interfaces/author";
import { extractMessage } from "../../../util/rpcError";

export type RpcResponse<Data, ErrorData, SpecialErrorPages> =
  | RpcResponseSuccess<Data>
  | RpcResponseError<ErrorData, SpecialErrorPages>;

export type RpcResponseSuccess<Data> = {
  tag: "success";
  data: Data;
};

export type RpcResponseError<ErrorData, SpecialErrorPages> = {
  tag: "error";
  error: Error;
  specialErrorPage: undefined | SpecialErrorPages;
  data: undefined | ErrorData;
};

export type SpecialErrorPageFnParams<Data> = {
  rawData: unknown;
  data: Data | undefined;
  message: string;
  code: number;
};
export type SpecialErrorPageFn<Data, SpecialErrorPages extends string> = (
  params: SpecialErrorPageFnParams<Data>,
) => SpecialErrorPages | undefined;

export const processRpcError = <
  ErrorDataSchema extends z.ZodType,
  SpecialErrorPages extends string,
>(
  error: unknown,
  errorDataSchema: ErrorDataSchema,
  specialErrorPageFn: SpecialErrorPageFn<
    z.infer<ErrorDataSchema>,
    SpecialErrorPages
  >,
): RpcResponseError<z.infer<ErrorDataSchema>, SpecialErrorPages> => {
  let data: undefined | z.infer<ErrorDataSchema>;
  let specialErrorPage: undefined | SpecialErrorPages;

  if (error instanceof RpcError) {
    if (error.data) {
      const { success, data: parsedData } = errorDataSchema.safeParse(
        error.data,
      );
      if (success) {
        data = parsedData;
      }
    }

    const extractedErrorMessage = extractMessage(error);
    specialErrorPage = specialErrorPageFn({
      rawData: error.data,
      data,
      code: error.code,
      message: extractedErrorMessage,
    });
  }

  const coercedError =
    error instanceof Error ? error : new Error(String(error));

  return { tag: "error", error: coercedError, data, specialErrorPage };
};

export const specialErrorPagesMapFn =
  <SpecialErrorPages extends string>(specialErrorPagesMap: {
    [key: string]: SpecialErrorPages;
  }): SpecialErrorPageFn<any, SpecialErrorPages> =>
  ({ message }) =>
    specialErrorPagesMap[message];

export const processRpcSuccess = <T>(data: T): RpcResponseSuccess<T> => ({
  tag: "success",
  data,
});

const processRpcCall = async <
  Data,
  ErrorDataSchema extends z.ZodType,
  SpecialErrorPages extends string,
>(
  call: () => Promise<Data>,
  errorDataSchema: ErrorDataSchema,
  specialErrorPagesFn: SpecialErrorPageFn<
    z.infer<ErrorDataSchema>,
    SpecialErrorPages
  >,
): Promise<RpcResponse<Data, z.infer<ErrorDataSchema>, SpecialErrorPages>> => {
  try {
    return processRpcSuccess(await call());
  } catch (error) {
    return processRpcError(error, errorDataSchema, specialErrorPagesFn);
  }
};

export type SubmitAndWatchStatus = { tag: "status"; status: ExtrinsicStatus };

export type SubmitAndWatchWithErrors<T, ErrorData, SpecialErrorPages> =
  | T
  | RpcResponseError<ErrorData, SpecialErrorPages>;

export const catchSubmitAndWatchErrors = <
  T,
  ErrorDataSchema extends z.ZodType,
  SpecialErrorPages extends string,
>(
  errorDataSchema: ErrorDataSchema,
  specialErrorPagesFn: SpecialErrorPageFn<
    z.infer<ErrorDataSchema>,
    SpecialErrorPages
  >,
): OperatorFunction<
  T,
  T | RpcResponseError<z.infer<ErrorDataSchema>, SpecialErrorPages>
> =>
  catchError((error, caught) => {
    const errorValue = processRpcError(
      error,
      errorDataSchema,
      specialErrorPagesFn,
    );
    return of(errorValue);
  });

export const mapSubmitAndWatchValues = (): OperatorFunction<
  ExtrinsicStatus,
  SubmitAndWatchStatus
> => map((status) => ({ tag: "status", status }) as const);

export type WrappedSubmitAndWatch<ErrorData, SpecialErrorPages> =
  | SubmitAndWatchStatus
  | RpcResponseError<ErrorData, SpecialErrorPages>;

export const wrapSubmitAndWatch = <
  ErrorDataSchema extends z.ZodType,
  SpecialErrorPages extends string,
>(
  errorDataSchema: ErrorDataSchema,
  specialErrorPagesFn: SpecialErrorPageFn<
    z.infer<ErrorDataSchema>,
    SpecialErrorPages
  >,
): OperatorFunction<
  ExtrinsicStatus,
  WrappedSubmitAndWatch<z.infer<ErrorDataSchema>, SpecialErrorPages>
> =>
  pipe(
    mapSubmitAndWatchValues(),
    catchSubmitAndWatchErrors(errorDataSchema, specialErrorPagesFn),
  );

export default processRpcCall;
