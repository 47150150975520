import "fontsource-roboto";
import React from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import App from "./App";
import { notifyUpdate } from "./components/AppUpdateContext";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// Register service worker to ensure we can hook the web requests.
serviceWorkerRegistration.register({
  onUpdate: notifyUpdate,
});

reportWebVitals(console.log);
