import authenticateV2 from "./errorPageParams/authenticateV2";
import authTx from "./errorPageParams/authTx";
import enrollV2 from "./errorPageParams/enrollV2";
import { buildSpecificErrorPage } from "./GenericErrorPage";

export const EnrollV2ErrorPage = buildSpecificErrorPage(enrollV2);

export const AuthTxErrorPage = buildSpecificErrorPage(authTx);

export const AuthenticateV2ErrorPage = buildSpecificErrorPage(authenticateV2);
