import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { GAP, GenericErrorShape } from "../GenericErrorPage";
import { validatorKeyNotAvailable } from "./common";

const params = {
  lookupMap: {
    personAlreadyEnrolled: {
      title: "Person Already Enrolled",
      hint: [
        "You are already enrolled with this biometrics server. There is no need to enroll with a biometrics server more than once.",
        GAP,
        "If you have lost access to the key you have enrolled with you will have to wait for the next biometrics server generation. Biometrics servers are rotated roughly every 6 months.",
      ],
      customActions: [
        <Button
          key="custom-action-to-authenticate"
          fullWidth
          size="large"
          variant="contained"
          color="success"
          component={Link}
          to="./../../authenticate"
        >
          Authenticate
        </Button>,
      ],
    } as GenericErrorShape,
    publicKeyAlreadyUsed: {
      title: "Public Key Already Used",
      hint: [
        "They public key you are trying to enroll with is already used by someone else.",
        GAP,
        "This is highly unlikely you have generated the same random key, so it is most likely an operational error on your side. Please double-check your setup, maybe you are using someone else’s key.",
        "It is also a possibility that your private key has leaked, and someone else already used it to enroll with this biometric server.",
        GAP,
        "It may also be a false-negative biometric matching case - if you know for sure this is your key and you have enrolled with it.",
        "Well, in this case you are lucky and you get to run a second node! You can generate a new key, and try enrolling again. Just make sure to back up your current key to maintain access to it if needed later.",
      ],
    } as GenericErrorShape,
    validatorKeyNotAvailable,
  },
  defaultLookup: {
    title: "Enrollment Failed",
    hint: ["Something went wrong."],
  } as GenericErrorShape,
} as const;

export default params;
