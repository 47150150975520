import React, { useEffect, useState } from "react";
import { State, FaceTecSDKContext } from "./state";

type Props = {
  children: React.ReactNode;
  faceTecInit: () => Promise<boolean>;
  faceTecUnload: () => Promise<void>;
};

const FaceTecSDKInit: React.FC<Props> = (props) => {
  const { children, faceTecInit, faceTecUnload } = props;

  const [value, setValue] = useState<State>({ tag: "pending", data: {} });

  useEffect(() => {
    const load = faceTecInit()
      .then((success) => {
        setValue(
          success ? { tag: "ready", data: {} } : { tag: "failed", data: {} },
        );
      })
      .catch((error: Error) => {
        setValue({ tag: "error", data: { error } });
      });
    return () => {
      load.then(() => faceTecUnload());
    };
  }, [faceTecInit, faceTecUnload]);

  return (
    <FaceTecSDKContext.Provider value={value}>
      {children}
    </FaceTecSDKContext.Provider>
  );
};

export default FaceTecSDKInit;
