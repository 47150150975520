import { GAP, GenericErrorShape } from "../GenericErrorPage";

export const validatorKeyNotAvailable = {
  title: "Validator Key Not Available",
  hint: [
    "The validator key is used to identify you in the network as a validator.",
    GAP,
    "This node does not have a validator key configured. Insert a validator key into the node key store before enrolling or authenticating.",
    GAP,
    "This can be done in the Humanode Launcher or via command line.",
  ],
} as GenericErrorShape;
