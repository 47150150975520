import { load } from "hybridconfig";
import { useEffect, useState } from "react";
import { z } from "zod";

const StringBooleanSchema = z.preprocess((val) => {
  if (typeof val === "string") {
    if (val.toLowerCase() === "true") return true;
    if (val.toLowerCase() === "false") return false;
  }
  return val;
}, z.boolean());

const ConfigSchema = z.object({
  showOutdatedNodeWarning: StringBooleanSchema,
  forceLegacyFlow: StringBooleanSchema,
});
type Config = z.infer<typeof ConfigSchema>;
type AllowedKeys = keyof Config;

const loader = load<AllowedKeys>({
  remoteConfigPath: "/config.json",
  envKeyPrefix: "APP_",
}).then(ConfigSchema.parse);

let loadedConfig: Config | undefined;

loader.then((config) => {
  loadedConfig = config;
});

type LoaderState =
  | {
      tag: "pending";
    }
  | {
      tag: "ready";
    }
  | {
      tag: "error";
      error: Error;
    };

export const useConfigLoader = (): LoaderState => {
  const [state, setState] = useState<LoaderState>(
    loadedConfig === undefined ? { tag: "pending" } : { tag: "ready" },
  );

  useEffect(() => {
    loader
      .then(() => setState({ tag: "ready" }))
      .catch((error: Error) => setState({ tag: "error", error }));
  }, []);

  return state;
};

export const useConfig = (): Config => {
  if (loadedConfig === undefined) {
    throw new Error("Loading configuration is not finished yet");
  }

  return loadedConfig;
};

export default loader;
