import { ApiRx } from "@polkadot/api";
import { StateVariant } from "@tagged-state/core";
import { firstValueFrom } from "rxjs";

export interface EpochProgress {
  total: number;
  current: number;
}

export type EpochProgressState =
  | StateVariant<"uninit">
  | StateVariant<"progress", EpochProgress>
  | StateVariant<"error", { error: Error }>;

export default async function epochProgress(
  api: ApiRx,
): Promise<EpochProgress> {
  const sessionInfo = await firstValueFrom(api.derive.session?.progress());
  return sessionInfo.isEpoch
    ? {
        total: sessionInfo.sessionLength.toNumber(),
        current: sessionInfo.sessionProgress.toNumber(),
      }
    : {
        total: 0,
        current: 0,
      };
}
