import { ApiRx } from "@polkadot/api";
import { firstValueFrom } from "rxjs";
import { StateVariant } from "@tagged-state/core";
import { FrameSystemAccountInfo } from "@polkadot/types/lookup";

export type BalanceState =
  | StateVariant<"uninit">
  | StateVariant<"ready", { accountInfo: FrameSystemAccountInfo }>
  | StateVariant<"error", { error: Error }>;

export const getValidatorPublicKey = (api: ApiRx) => {
  if (!Boolean(api.rpc.authorExt?.getValidatorPublicKey)) {
    throw new Error("Unsupported getValidatorPublicKey function");
  }

  return firstValueFrom(api.rpc.authorExt.getValidatorPublicKey());
};

export default async function balance(api: ApiRx) {
  const key = await getValidatorPublicKey(api);
  if (!key) throw new Error("Missing validator public key");

  return firstValueFrom(api.query.system.account(key.toString()));
}
