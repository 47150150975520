import { ApiRx } from "@polkadot/api";
import { enrollV2 as wrappedEnrollV2 } from "../wrappers";
import processRpcCall, { specialErrorPagesMapFn } from "./processRpcCall";
import { RetryOrBlobSchema } from "./errorData";

export type EnrollV2Response = Awaited<ReturnType<typeof enrollV2>>;

const errorMessageToSpecialErrorPage = {
  "server error: public key already used": "publicKeyAlreadyUsed",
  "server error: person already enrolled": "personAlreadyEnrolled",
  "validator key not available": "validatorKeyNotAvailable",
} as const;

const enrollV2 = async (
  api: ApiRx,
  livenessData: {
    faceScan: string;
    auditTrailImage: string;
    lowQualityAuditTrailImage: string;
  },
) =>
  processRpcCall(
    () => wrappedEnrollV2(api, livenessData),
    RetryOrBlobSchema,
    specialErrorPagesMapFn(errorMessageToSpecialErrorPage),
  );

export default enrollV2;
