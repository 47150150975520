import { useMemo, useState } from "react";
import { useInterval, useBoolean } from "react-use";

export const useCountdown = (
  ms: number,
  opts?: { deltaMs?: number },
): number => {
  const [timeLeft, setTimeLeft] = useState(ms);
  const deltaMs = opts?.deltaMs ?? 250;
  const [isRunning, toggleIsRunning] = useBoolean(true);
  const initial = useMemo(() => Date.now(), []);

  useInterval(
    () => {
      const newValue = Math.max(0, ms - (Date.now() - initial));
      setTimeLeft(newValue);
      toggleIsRunning(newValue > 0);
    },
    isRunning ? deltaMs : null,
  );

  return timeLeft;
};
