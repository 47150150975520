import { useCallback, useState } from "react";
import { Observable, BehaviorSubject } from "rxjs";
import { useSubscription } from "observable-hooks";
import {
  ComponentGuard,
  ContextComponentGuardProps,
} from "@tagged-state/react";

export type BaseState = { tag: string; data: any };

function createTaggedStateObservable<T, State extends BaseState>() {
  const initialState = { tag: "uninit", data: {} } as State;
  const subject = new BehaviorSubject<State>(initialState);

  type OnState = (taggedState: State) => void;
  const useTaggedStateSubscription = (callback: OnState) => {
    useSubscription(subject, callback);
  };

  const setTaggedState = (state: State) => subject.next(state);

  const useDriveTaggedStateFromObservable = (
    observable$: Observable<T>,
    next: (value: T) => Promise<State>,
    error: (error: any) => Promise<State>,
  ) => {
    const handleNext = useCallback(
      async (value: T) => {
        const state = await next(value);
        setTaggedState(state);
      },
      [next],
    );

    const handleError = useCallback(
      async (value: T) => {
        const state = await error(value);
        setTaggedState(state);
      },
      [error],
    );

    return useSubscription(observable$, handleNext, handleError);
  };

  const Guard: React.FC<ContextComponentGuardProps<State, any>> = (props) => {
    const [guardState, setGuardState] = useState<State>(initialState);

    useTaggedStateSubscription(setGuardState);

    return <ComponentGuard taggedState={guardState} {...props} />;
  };

  return {
    subject,
    setTaggedState,
    useDriveTaggedStateFromObservable,
    useTaggedStateSubscription,
    Guard,
  };
}

export default createTaggedStateObservable;
