import React, { useEffect, useState } from "react";
import { StateProps } from "@tagged-state/core";
import { FaceTecSessionTokenContext, State } from "./state";

type Props = {
  children: React.ReactNode;
  getSessionToken: () => Promise<StateProps<State>["ready"]>;
};

const FaceTecSessionTokenInit: React.FC<Props> = (props) => {
  const { children, getSessionToken } = props;

  const [value, setValue] = useState<State>({ tag: "pending", data: {} });

  useEffect(() => {
    getSessionToken()
      .then((data) => {
        setValue({ tag: "ready", data });
      })
      .catch((error) => {
        setValue({ tag: "error", data: { error } });
      });
  }, [getSessionToken]);

  return (
    <FaceTecSessionTokenContext.Provider value={value}>
      {children}
    </FaceTecSessionTokenContext.Provider>
  );
};

export default FaceTecSessionTokenInit;
