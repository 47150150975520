import { distinctUntilChanged, Observable } from "rxjs";
import { BioauthStatusState } from "../../lib/dashboard/bioauthStatus";
import createTaggedStateObservable from "../../lib/observable-tagged-state/createTaggedStateObservable";
import { BioauthStatus } from "../../interfaces/generated";

const { Guard: BioauthStatusGuard, useDriveTaggedStateFromObservable } =
  createTaggedStateObservable<BioauthStatus, BioauthStatusState>();

const useBioauthStatusInit = (observable$: Observable<BioauthStatus>) => {
  const bioauthStatusObservable$ = observable$.pipe(
    distinctUntilChanged((prev, curr) => curr.eq(prev)),
  );

  return useDriveTaggedStateFromObservable(
    bioauthStatusObservable$,
    async (data): Promise<BioauthStatusState> => {
      if (data.isActive) {
        return {
          tag: "active",
          data: {
            expiresAt: data.asActive.expiresAt.toNumber(),
          },
        };
      }

      if (data.isInactive) {
        return {
          tag: "inactive",
          data: {},
        };
      }

      return { tag: "unknown", data: {} };
    },
    async (error) => ({ tag: "error", data: { error } }),
  );
};

export { BioauthStatusGuard, useBioauthStatusInit };
