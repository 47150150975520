import { Params, useParams } from "react-router-dom";

type CheckedParams<Key extends string> = {
  readonly [key in Key]: string;
};

function useRequiredParams<Key extends string>(
  ...keys: Array<Key>
): Readonly<CheckedParams<Key>> {
  const params = useParams<Key>() as Readonly<Params<Key>>;
  keys.forEach((key) => {
    if (params[key] === undefined) {
      throw new Error(
        `App internal error: routing parameter ${key} is required here, but was not passed!`,
      );
    }
  });
  return params as Readonly<CheckedParams<Key>>;
}

export default useRequiredParams;
