import { ApiRx } from "@polkadot/api";
import {
  BN,
  bnMin,
  bnToBn,
  BN_MAX_INTEGER,
  BN_ONE,
  extractTime,
  BN_THOUSAND,
  BN_TWO,
} from "@polkadot/util";
import type { Time } from "@polkadot/util/types";
import { useMemo } from "react";

// copied from https://github.com/polkadot-js/apps/blob/cc56f65179215c85485c8ed33c4c82ffc5a1c260/packages/react-hooks/src/useBlockInterval.ts#L19
const THRESHOLD = BN_THOUSAND.div(BN_TWO);
const DEFAULT_TIME = new BN(6_000);
const A_DAY = new BN(24 * 60 * 60 * 1000);
export function calcInterval(api: ApiRx): BN {
  return bnMin(
    A_DAY,
    // Babe, e.g. Relay chains (Substrate defaults)
    api.consts.babe?.expectedBlockTime ||
      // POW, eg. Kulupu
      api.consts.difficulty?.targetBlockTime ||
      // Subspace
      api.consts.subspace?.expectedBlockTime ||
      // Check against threshold to determine value validity
      (api.consts.timestamp?.minimumPeriod.gte(THRESHOLD)
        ? // Default minimum period config
          api.consts.timestamp.minimumPeriod.mul(BN_TWO)
        : api.query.parachainSystem
          ? // default guess for a parachain
            DEFAULT_TIME.mul(BN_TWO)
          : // default guess for others
            DEFAULT_TIME),
  );
}

export const blockTimeString = (ms: number) => {
  const time = extractTime(Math.abs(ms));
  const { days, hours, minutes, seconds } = time;

  return `${ms < 0 ? "+" : ""}${[
    days ? (days > 1 ? `${days} days` : "1 day") : null,
    hours ? (hours > 1 ? `${hours} hrs` : "1 hr") : null,
    minutes ? (minutes > 1 ? `${minutes} mins` : "1 min") : null,
    seconds ? (seconds > 1 ? `${seconds} s` : "1 s") : null,
  ]
    .filter((s): s is string => !!s)
    .slice(0, 2)
    .join(" ")}`;
};

export const calcResult = (
  blockTime: BN,
  blocks: BN,
): [number, string, Time] => {
  const value = bnMin(BN_MAX_INTEGER, blockTime.mul(blocks)).toNumber();
  const time = extractTime(Math.abs(value));

  return [blockTime.toNumber(), blockTimeString(value), time];
};

export const useBlockTime = (
  api: ApiRx,
  blocks: number | BN = BN_ONE,
): [number, string, Time] | null => {
  const blockTime = calcInterval(api);

  return useMemo(
    () => (blockTime === null ? null : calcResult(blockTime, bnToBn(blocks))),
    [blockTime, blocks],
  );
};
