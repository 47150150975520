import { RpcErrorInterface } from "@polkadot/rpc-provider/types";

export function isRpcErrorInterface(
  value: any,
): value is RpcErrorInterface<unknown> {
  return (
    typeof value === "object" &&
    typeof value.code === "number" &&
    typeof value.message === "string" &&
    typeof value.stack === "string"
  );
}

export function isShouldRetry(value: any): value is { shouldRetry: true } {
  return value?.shouldRetry === true;
}
