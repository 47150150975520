import { z } from "zod";

export const ShouldRetrySchema = z.object({
  shouldRetry: z.literal(true),
});
export const ScanResultBlobSchema = z.object({
  scanResultBlob: z.string(),
});
export const RetryOrBlobSchema = z.union([
  ScanResultBlobSchema,
  ShouldRetrySchema,
]);
