const definitions = {
  rpc: {
    setKeys: {
      description: "Set session keys to the node",
      params: [{ name: "session_keys", type: "Bytes" }],
      type: "Null",
    },
    getValidatorPublicKey: {
      description: "Get validator public key",
      params: [],
      type: "Option<AccountId>",
    },
  },
  types: {},
};

export default definitions;
