import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { GAP, GenericErrorShape } from "../GenericErrorPage";
import { validatorKeyNotAvailable } from "./common";

const params = {
  lookupMap: {
    personNotFound: {
      title: "Person Not Found",
      hint: [
        "You are not found among the enrolled users in the current biometrics server generation. Did you enroll?",
      ],
      customActions: [
        <Button
          key="custom-action-to-enroll"
          fullWidth
          size="large"
          variant="outlined"
          component={Link}
          to="./../setup-node/enroll-warning"
        >
          Enroll
        </Button>,
      ],
    } as GenericErrorShape,
    signatureInvalid: {
      title: "Authenticating With a Different Key",
      hint: [
        "You are enrolled with the biometrics server with a different key. Use the same key you enrolled with.",
        GAP,
        "If you have lost access to your original key you will have to wait for the next biometrics server generation. Biometrics servers are rotated roughly every 6 months.",
      ],
    } as GenericErrorShape,
    validatorKeyNotAvailable,
  },
  defaultLookup: {
    title: "Authentication Failed",
    hint: ["Something went wrong."],
  } as GenericErrorShape,
} as const;

export default params;
