import { ApiRx } from "@polkadot/api";
import { z } from "zod";
import {
  wrapSubmitAndWatch,
  WrappedSubmitAndWatch,
  SpecialErrorPageFn,
} from "./processRpcCall";

type SpecialErrorPages =
  (typeof errorRawDataToSpecialErrorPage)[keyof typeof errorRawDataToSpecialErrorPage];

export type SendAuthenticateTxState = WrappedSubmitAndWatch<
  never,
  SpecialErrorPages
>;

const errorRawDataToSpecialErrorPage = {
  "Transaction has a bad signature": "authTicketSignatureInvalid",
  "Transaction is outdated": "nonceAlreadyUsed",
  "Transaction will be valid in the future": "alreadyAuthenticated",
} as const;

const specialErrorPagesFn: SpecialErrorPageFn<never, SpecialErrorPages> = (
  params,
) => {
  const { message, rawData } = params;

  if (message !== "Invalid Transaction") {
    return;
  }

  return errorRawDataToSpecialErrorPage[
    rawData as keyof typeof errorRawDataToSpecialErrorPage
  ];
};

const sendAuthenticateTx = (
  api: ApiRx,
  ticket: Uint8Array,
  ticketSignature: Uint8Array,
) => {
  const tx = api.tx.bioauth.authenticate({
    ticket,
    ticketSignature,
  });

  const status$ = api.rpc.author.submitAndWatchExtrinsic(tx);

  return status$.pipe(wrapSubmitAndWatch(z.never(), specialErrorPagesFn));
};

export default sendAuthenticateTx;
