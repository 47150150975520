import { ApiRx } from "@polkadot/api";
import { PropsWithChildren, useMemo } from "react";
import { exhaustMap, mergeMap, Observable } from "rxjs";
import balance from "../../lib/dashboard/balance";
import epochProgress from "../../lib/dashboard/epochProgress";
import bioauthStatus from "../../lib/dashboard/bioauthStatus";
import { useBalanceInit } from "./BalanceInit";
import { useSyncStateInit } from "./SyncStateInit";
import { useEpochProgressInit } from "./EpochProgressInit";
import { useBioauthStatusInit } from "./BioauthStatusInit";

interface DashboardInitProps<T> extends PropsWithChildren {
  api: ApiRx;
  observable$: Observable<T>;
}

export default function DashboardInit<T>(props: DashboardInitProps<T>) {
  const { api, observable$, children } = props;
  const balanceObservable$ = useMemo(
    () => observable$.pipe(exhaustMap(() => balance(api))),
    [api, observable$],
  );
  const epochProggressObservable$ = useMemo(
    () => observable$.pipe(exhaustMap(() => epochProgress(api))),
    [api, observable$],
  );
  const bioauthStatusObservable$ = useMemo(
    () => observable$.pipe(mergeMap(() => bioauthStatus(api))),
    [api, observable$],
  );

  useSyncStateInit({
    api,
  });
  useEpochProgressInit(epochProggressObservable$);
  useBalanceInit(balanceObservable$);
  useBioauthStatusInit(bioauthStatusObservable$);

  return <>{children}</>;
}
