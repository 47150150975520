import { StateVariant } from "@tagged-state/core";
import { createContext } from "@tagged-state/react";

export type State =
  | StateVariant<"uninit">
  | StateVariant<"pending">
  | StateVariant<"ready">
  | StateVariant<"failed">
  | StateVariant<"error", { error: Error }>;

const { Context, ComponentGuard } = createContext<State>({
  tag: "uninit",
  data: {},
});

export const FaceTecSDKContext = Context;
export const FaceTecSDKGuard = ComponentGuard;
