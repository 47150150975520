import type { Observable } from "rxjs";
import { FrameSystemAccountInfo } from "@polkadot/types/lookup";
import { BalanceState } from "../../lib/dashboard/balance";
import createTaggedStateObservable from "../../lib/observable-tagged-state/createTaggedStateObservable";
const { Guard: BalanceGuard, useDriveTaggedStateFromObservable } =
  createTaggedStateObservable<FrameSystemAccountInfo, BalanceState>();

const useBalanceInit = (observable$: Observable<FrameSystemAccountInfo>) =>
  useDriveTaggedStateFromObservable(
    observable$,
    async (accountInfo) => ({ tag: "ready", data: { accountInfo } }),
    async (error) => ({ tag: "error", data: { error } }),
  );

export { BalanceGuard, useBalanceInit };
