import { ApiRx, WsProvider } from "@polkadot/api";
import { ApiOptions } from "@polkadot/api/types";
import { ProviderInterface } from "@polkadot/rpc-provider/types";
import { lastValueFrom } from "rxjs";

import * as definitions from "../../interfaces/generated/definitions";

export const createProvider = async (
  url: string,
): Promise<ProviderInterface> => {
  const isWsUrl = /^wss?:\/\//.test(url);

  if (!isWsUrl) {
    throw new Error(`Expected ws:// or wss:// URL, got ${url}`);
  }

  return new WsProvider(url);
};

export const createApi = async (
  options: Pick<ApiOptions, "provider" | "throwOnConnect">,
): Promise<ApiRx> => {
  const types = Object.values(definitions).reduce(
    (res, { types }) => ({ ...res, ...types }),
    {},
  );
  const rpc = Object.entries(definitions).reduce(
    (res, [name, { rpc }]) => ({ ...res, [name]: rpc }),
    {},
  );
  try {
    return await lastValueFrom(
      ApiRx.create({
        ...options,
        types,
        rpc,
      }),
    );
  } catch (error) {
    if (error instanceof Event) {
      if (error.target instanceof WebSocket) {
        throw new Error(`Unable to connect to ${error.target.url}`);
      }
      throw new Error(
        `Unexpected error event target ${JSON.stringify(error.target)}`,
      );
    }
    throw error;
  }
};
