import { ApiRx } from "@polkadot/api";
import { RuntimeVersion } from "@polkadot/types/interfaces";
import { Struct } from "@polkadot/types-codec";
import { firstValueFrom, lastValueFrom } from "rxjs";
import { FaceTecInitParams } from "../facetec/logic/faceTecInit";

export const getFacetecDeviceSdkParams = async (
  api: ApiRx,
): Promise<FaceTecInitParams> => {
  const { deviceKeyIdentifier, productionKey, publicFaceMapEncryptionKey } =
    await lastValueFrom(api.rpc.bioauth.getFacetecDeviceSdkParams());

  const params = {
    deviceKeyIdentifier: deviceKeyIdentifier.toString(),
    publicFaceMapEncryptionKey: publicFaceMapEncryptionKey.toString(),
  };

  if (productionKey.isNone) {
    return params;
  }

  return {
    ...params,
    productionKey: productionKey.unwrap().toString(),
  };
};

export const getFacetecSessionToken = async (api: ApiRx): Promise<string> => {
  const res = await lastValueFrom(api.rpc.bioauth.getFacetecSessionToken());
  return res.toString();
};

export const enroll = async (
  api: ApiRx,
  livenessData: {
    faceScan: string;
    auditTrailImage: string;
    lowQualityAuditTrailImage: string;
  },
): Promise<void> => {
  await lastValueFrom(
    api.rpc.bioauth.enroll({
      faceScan: api.createType("Text", livenessData.faceScan),
      auditTrailImage: api.createType("Text", livenessData.auditTrailImage),
      lowQualityAuditTrailImage: api.createType(
        "Text",
        livenessData.lowQualityAuditTrailImage,
      ),
    }),
  );
};

export const enrollV2 = async (
  api: ApiRx,
  livenessData: {
    faceScan: string;
    auditTrailImage: string;
    lowQualityAuditTrailImage: string;
  },
) => {
  const res = await lastValueFrom(
    api.rpc.bioauth.enrollV2({
      faceScan: api.createType("Text", livenessData.faceScan),
      auditTrailImage: api.createType("Text", livenessData.auditTrailImage),
      lowQualityAuditTrailImage: api.createType(
        "Text",
        livenessData.lowQualityAuditTrailImage,
      ),
    }),
  );

  return {
    scanResultBlob: res.scanResultBlob.isSome
      ? res.scanResultBlob.value.toString()
      : undefined,
  };
};

export const authenticate = async (
  api: ApiRx,
  livenessData: {
    faceScan: string;
    auditTrailImage: string;
    lowQualityAuditTrailImage: string;
  },
): Promise<void> => {
  await lastValueFrom(
    api.rpc.bioauth.authenticate({
      faceScan: api.createType("Text", livenessData.faceScan),
      auditTrailImage: api.createType("Text", livenessData.auditTrailImage),
      lowQualityAuditTrailImage: api.createType(
        "Text",
        livenessData.lowQualityAuditTrailImage,
      ),
    }),
  );
};

export const authenticateV2 = async (
  api: ApiRx,
  livenessData: {
    faceScan: string;
    auditTrailImage: string;
    lowQualityAuditTrailImage: string;
  },
) => {
  const res = await lastValueFrom(
    api.rpc.bioauth.authenticateV2({
      faceScan: api.createType("Text", livenessData.faceScan),
      auditTrailImage: api.createType("Text", livenessData.auditTrailImage),
      lowQualityAuditTrailImage: api.createType(
        "Text",
        livenessData.lowQualityAuditTrailImage,
      ),
    }),
  );

  return {
    authTicket: res.authTicket.toU8a(),
    authTicketSignature: res.authTicketSignature.toU8a(),
    scanResultBlob: res.scanResultBlob.isSome
      ? res.scanResultBlob.value.toString()
      : undefined,
  };
};

export const rotateKeys = async (api: ApiRx): Promise<string> =>
  await lastValueFrom(api.rpc.author.rotateKeys()).then((bytes) =>
    bytes.toJSON(),
  );

export const setKeys = async (api: ApiRx, bytes: string): Promise<void> => {
  await lastValueFrom(
    api.rpc.authorExt.setKeys(api.createType("Bytes", bytes)),
  );
};

type RuntimeVersionJSON = {
  [Key in Exclude<keyof RuntimeVersion, keyof Struct>]: string;
};
export const getRuntimeVersion = (api: ApiRx): Promise<RuntimeVersionJSON> =>
  firstValueFrom(api.rpc.state.getRuntimeVersion()).then(
    (runtimeVersion) => runtimeVersion.toJSON() as RuntimeVersionJSON,
  );

export const getSystemChain = (api: ApiRx): Promise<string> =>
  firstValueFrom(api.rpc.system.chain()).then((text) => text.toString());
