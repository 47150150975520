import FaceTecSDK, { FaceTecFaceScanProcessor } from "facetec-sdk";

export type LivenessData = {
  faceScan: string;
  auditTrailImage: string;
  lowQualityAuditTrailImage: string;
};

export type Effect =
  | { blob: string }
  | { command: "success" | "retry" }
  | undefined;

export type LivenessDataHandler = (
  livenessData: LivenessData,
) => Promise<Effect>;
type MakeProcessorProps = {
  handleLivenessData: LivenessDataHandler;
  onDone: () => void;
  onError: (error: Error) => void;
};

const makeProcessor = (props: MakeProcessorProps): FaceTecFaceScanProcessor => {
  const { handleLivenessData, onDone, onError } = props;
  const handleError = (error: Error) => {
    onError(error);
  };
  const process: FaceTecFaceScanProcessor["processSessionResultWhileFaceTecSDKWaits"] =
    (sessionResult, effect) => {
      const { faceScan, status } = sessionResult;

      if (
        status !== FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully
      ) {
        const friendlyStatus =
          FaceTecSDK.getFriendlyDescriptionForFaceTecSessionStatus(status);
        const error = new Error(
          `Session was not completed successfully, cancelling.  \
          Status: ${friendlyStatus} (${status})`,
        );
        handleError(error);
        effect.cancel();
        return;
      }

      if (!faceScan) {
        throw new Error("Expected face scan to be present");
      }

      handleLivenessData({
        faceScan,
        auditTrailImage: sessionResult.auditTrail[0] || "",
        lowQualityAuditTrailImage: sessionResult.lowQualityAuditTrail[0] || "",
      }).then(
        (variant) => {
          if (!variant) return effect.cancel();

          if ("blob" in variant) {
            return effect.proceedToNextStep(variant.blob);
          }

          switch (variant.command) {
            case "success":
              return effect.succeed();
            case "retry":
              return effect.retry();
            default:
              return effect.cancel();
          }
        },
        (error) => {
          handleError(error);
          effect.cancel();
        },
      );
    };

  return {
    onFaceTecSDKCompletelyDone: onDone,
    processSessionResultWhileFaceTecSDKWaits: process,
  } as FaceTecFaceScanProcessor;
};

export default makeProcessor;
