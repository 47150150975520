import { distinctUntilChanged, exhaustMap, interval, startWith } from "rxjs";
import { ApiRx } from "@polkadot/api";
import syncState, { SyncState } from "../../lib/dashboard/syncState";
import createTaggedStateObservable from "../../lib/observable-tagged-state/createTaggedStateObservable";

const { Guard: SyncStateGuard, useDriveTaggedStateFromObservable } =
  createTaggedStateObservable<SyncState, SyncState>();

const interval$ = interval(1000).pipe(startWith(0));

type Params = {
  api: ApiRx;
};

const useSyncStateInit = ({ api }: Params) => {
  const syncStateObservable$ = interval$
    .pipe(exhaustMap(() => syncState(api)))
    .pipe(distinctUntilChanged((prev, curr) => prev.tag === curr.tag));

  return useDriveTaggedStateFromObservable(
    syncStateObservable$,
    async (state) => state,
    async (error) => ({ tag: "error", data: { error } }),
  );
};

export { SyncStateGuard, useSyncStateInit };
