import React, { useCallback, useRef } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import Layout from "./Layout";

const StyledForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

type Props = {
  onConnect: (url: string) => void;
};

const NodeConnectionParamsEntryPage: React.FC<Props> = (props) => {
  const { onConnect } = props;

  const urlRef = useRef("ws://localhost:9944");

  const handleUrlChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();
      urlRef.current = event.currentTarget.value;
    },
    [],
  );

  const handleConnect = useCallback(
    (event: React.SyntheticEvent<HTMLFormElement>) => {
      event.preventDefault();
      onConnect(urlRef.current);
    },
    [onConnect],
  );

  return (
    <Layout logo>
      <StyledForm noValidate onSubmit={handleConnect}>
        <TextField
          name="humanodeUrl"
          id="humanode-url"
          label="Humanode RPC URL"
          fullWidth
          defaultValue={urlRef.current}
          onChange={handleUrlChange}
          variant="standard"
        />
        <Button type="submit" variant="contained" color="primary">
          Connect to your humanode peer
        </Button>
      </StyledForm>
      <Typography variant="body2">or</Typography>
      <Typography variant="body2">scan the QR code</Typography>
    </Layout>
  );
};

export default NodeConnectionParamsEntryPage;
