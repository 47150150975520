import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ErrorDetails from "../components/ErrorDetails";
import Layout from "./Layout";

export const GAP = Symbol("<gap />");

export type GenericErrorShape = {
  title: React.ReactNode;
  hint: (React.ReactNode | typeof GAP)[];
  customActions?: React.ReactNode[];
};
type GenericErrorPageProps = GenericErrorShape & {
  error: Error;
};
const GenericErrorPage: React.FC<GenericErrorPageProps> = (props) => (
  <Layout logo>
    <Container
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Typography variant="h4" align="center">
        {props.title}
      </Typography>

      <Stack>
        {props.hint.map((hint, index) => {
          if (hint === GAP) {
            return <Box key={`gap_${index}`} sx={{ minHeight: 16 }} />;
          }

          return (
            <Typography key={`hint_${index}`} align="center">
              {hint}
            </Typography>
          );
        })}
      </Stack>

      <ErrorDetails error={props.error} />

      {props.customActions ? props.customActions : null}

      <Button
        fullWidth
        size="large"
        variant="outlined"
        onClick={() => window.location.reload()}
      >
        Try again
      </Button>

      <Button size="large" fullWidth variant="text" component={Link} to="./..">
        Go back
      </Button>
    </Container>
  </Layout>
);

export type BuildSpecificErrorPageParams<LookupKeys extends string> = {
  lookupMap: { [Key in LookupKeys]: GenericErrorShape };
  defaultLookup: GenericErrorShape;
};
export type SpecificErrorPageProps<LookupKeys> = {
  kind: LookupKeys | undefined;
  error: Error;
};
export function buildSpecificErrorPage<LookupKeys extends string>(
  params: BuildSpecificErrorPageParams<LookupKeys>,
): React.FC<SpecificErrorPageProps<LookupKeys>> {
  const { defaultLookup, lookupMap } = params;
  return (props) => {
    const lookup = lookupMap[props.kind!];

    const title = lookup?.title ?? defaultLookup.title;
    const hint = lookup?.hint ?? defaultLookup.hint;
    const customActions = lookup?.customActions;

    return (
      <GenericErrorPage
        title={title}
        hint={hint}
        error={props.error}
        customActions={customActions}
      />
    );
  };
}

export default GenericErrorPage;
